var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-over-right',{attrs:{"id":_vm.sorId,"editType":"standard"},on:{"showModal":_vm.showModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"font-semibold text-gray-900 text-22px"},[_vm._v(" "+_vm._s(_vm.getTitleText)+" ")])]},proxy:true}])},[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('AppInput',{attrs:{"type":"richselect","rules":"required","name":_vm.$t(
            'components.settingsManagement.riderApp.additionalMessages.addEdit.title.type'
          ),"label":_vm.$t(
            'components.settingsManagement.riderApp.additionalMessages.addEdit.title.type'
          ),"placeholder":"Select one","options":_vm.messageTypes,"disabled":_vm.isEditing,"hide-search-box":""},model:{value:(_vm.form.message_type),callback:function ($$v) {_vm.$set(_vm.form, "message_type", $$v)},expression:"form.message_type"}}),_c('AppInput',{attrs:{"type":"text","rules":"required","name":_vm.$t(
            'components.settingsManagement.riderApp.additionalMessages.addEdit.title.message'
          ),"label":_vm.$t(
            'components.settingsManagement.riderApp.additionalMessages.addEdit.title.message'
          )},model:{value:(_vm.form.message_text),callback:function ($$v) {_vm.$set(_vm.form, "message_text", $$v)},expression:"form.message_text"}}),_c('div',{staticClass:"flex justify-end items-center mt-6"},[_c('slide-over-right-trigger',{attrs:{"id":_vm.sorId}},[_c('anchor-button',{attrs:{"variant":"secondary"}},[_vm._v(_vm._s(_vm.$t('components.stepNavigation.cancel')))])],1),_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.isEditing ? _vm.$t('components.stepNavigation.update') : _vm.$t('components.stepNavigation.save')))])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }