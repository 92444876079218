export const HardwareMessageConfig = {
  api: {
    // get
    index: () => `/dashboard/organization-messages/`,
    // post
    create: () => `/dashboard/organization-messages/`,
    // patch
    update: (messageId) => `/dashboard/organization-messages/${messageId}/`,
    // patch
    delete: (messageId) => `/dashboard/organization-messages/${messageId}/`,
  },
  events: {
    name: 'hardware-message',
    // refresh-index-data
    refresh: `rid-hardware-message`,
    // slideover-right
    sorId: 'hardware-message',
    sorOpen: 'sor-open-hardware-message',
    sorClose: 'sor-close-hardware-message',
    sorToggle: 'sor-toggle-hardware-message',
    // editing-data
    editingData: 'edit-hardware-message-data',
    // viewing-data
    viewingData: 'view-hardware-message-data',
  },
}
